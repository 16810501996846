@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@import "./reset.scss";

body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.block-scroll {
    height: 100%;
    overflow: hidden;
  }

  input:-webkit-autofill {
    -webkit-background-clip: text;
  }
}
