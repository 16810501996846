.terms-conditions-wrapper {
  margin-bottom: 10px;
  h3 {
    margin-top: 20px;
    font-size: 14px;
    line-height: 17px;
    color: #1d242d;
  }
  p {
    margin-top: 10px;
    color: #787d83;
    font-size: 14px;
  }
}
