@import "styles/core.scss";

.enrollment-card {
  display: flex;
  flex-direction: column;
  height: 240px;
  align-items: center;
  padding: 35px 32px;
  border: 1px solid #787d83;
  box-sizing: border-box;
  border-radius: 16px;
  margin-bottom: 16px;
  &:hover {
    border: 1px solid #4361ee;
  }

  &--icon {
    margin-bottom: 10px;
  }
  &--title {
    font-weight: 600;
    font-size: 16px;
    color: $primary;
    margin-bottom: 10px;
    @include mq($until: desktop) {
      text-align: center;
    }
  }
  &--description {
    text-align: center;
    color: #787d83;
    font-size: 16px;
    line-height: 19.5px;
  }
  &--action {
    margin-top: 15px;
    color: $gray1;
    font-size: 16px;
    font-weight: 600;
  }
}
