@import "../styles/core.scss";

header {
  height: 120px;
  display: flex;
  flex-direction: column;
  @include mq($until: tablet) {
    flex-direction: column-reverse;
  }
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 32px;
  @include mq($until: tablet) {
    height: 112px;
    padding: 5px 11px;
  }

  .drawer-toggle {
    display: none;
    @include mq($until: desktopExtended) {
      display: inline;
    }
  }
}

.header-menu {
  @include mq($until: desktopExtended) {
    display: none;
  }

  &--item {
    display: inline-flex;
    align-items: center;
    .icon {
      line-height: 1;
      margin-right: 9px;
    }
    .label {
      color: $gray1;
      font-weight: 600;
      font-size: 16px;
      line-height: 19.5px;
    }
  }
}

.enrollment-button {
  height: 48px;
  display: inline-flex;
  align-items: center;
  padding: 0 24px;
  border-radius: 8px;
  background-color: #f72585;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  border: 0;
  cursor: pointer;
}

.information-bar {
  padding: 12px 32px;
  display: flex;
  justify-content: flex-end;
  @include mq($until: tablet) {
    justify-content: space-between;
    padding: 2px 11px 15px;
  }
  &--item {
    color: $gray2;
    font-size: 14px;
    @include mq($until: tablet) {
      font-size: 12px;
    }
    &:first-child {
      margin-right: 24px;
    }
  }
}

.header-body-separator {
  height: 51px;
  background-color: $primary;
  @include mq($until: tablet) {
    height: 64px;
  }
}

/**
  Layout
*/

.layout-wrapper {
  &--content {
    display: flex;
    flex-direction: column;
  }
}

.layout-content-container {
  max-width: 886px;
  margin: 0 auto;
  @include mq($until: desktop) {
    padding: 0 32px;
    max-width: auto;
  }
}

/**
Footer and elements
*/

footer {
  height: 112px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 32px;
  background: $gray4;
  .copyright-text {
    color: $gray2;
    font-size: 14px;
    line-height: 17px;
  }
  @include mq($from: tablet, $until: desktop) {
    .copyright-text {
      width: 158px;
    }
  }

  @include mq($until: tablet) {
    height: 174px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .copyright-text {
      width: 261px;
    }
    .mango-logo {
      margin-bottom: 25px;
    }
  }
}

.drawer-icon-wrapper {
  .big-drawer-icon,
  .small-drawer-icon {
    display: none;
    position: relative;
  }
  @include mq($until: desktopExtended) {
    .big-drawer-icon {
      display: inline-block;
      right: -25px;
    }
  }
  @include mq($until: tablet) {
    .small-drawer-icon {
      display: inline-block;
      right: -10px;
    }
    .big-drawer-icon {
      display: none;
    }
  }
}

.welcome-container--highlight {
  @include mq($until: tablet) {
    text-align: center;
  }
}

.desktop-view {
  &.hub {
    @include mq($until: desktop) {
      margin-bottom: 30px;
    }
  }
}

.home-example-card-container {
  @include mq($until: tablet) {
    .example-card-desc {
      margin-top: 27px;
      h4 {
        margin-bottom: 7px;
      }
      margin-bottom: 41px;
    }
    .example-card-wrapper {
      img {
        height: auto;
        width: 100%;
      }
    }
  }
}

.example-card-wrapper {
  @include mq($from: tablet, $until: desktop) {
    padding-right: 30px;
    img {
      height: auto;
      width: 100%;
    }
  }
}

.welcome-container-wrapper {
  @include mq($from: tablet, $until: desktop) {
    .home-example-card-container {
      display: flex;

      .card {
        flex: 2 1 66%;
      }
      .example-card-desc {
        flex: 1 1 33%;
        h4 {
          margin-bottom: 10px;
        }
      }
    }
  }
  @include mq($from: desktop) {
    display: flex;
    .description {
      flex: 4 1 auto;
    }
    .card-section {
      flex: 3 1 auto;
    }
    .hub-fix {
      margin-top: 65px;
    }
    .example-card-desc {
      margin-top: 15px;
    }
  }
}

.example-card-desc.copay-fix {
  @include mq($from: desktop) {
    margin-top: -10px;
  }
}
