.ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.7);
}

.ant-drawer-open {
  .ant-drawer-close {
    position: fixed;
    top: 83px;
    right: 280px;
  }
}

.ant-drawer-body {
  padding-left: 40px;
  padding-right: 40px;
}

.faux-drawer-wrapper {
  .header-menu {
    display: flex;
    flex-direction: column;
    .icon {
      margin-right: 15px !important;
    }
    .enrollment-button {
      height: 67px;
      line-height: 1.3em;
    }
  }
  .drawer-divider {
    height: 1px;
    background: #cdd6e1;
    margin: 20px 0;
    display: block !important;
  }
  .logo-wrapper {
    margin-top: 55px;
    transform: scale(0.86);
    transform-origin: left top;
  }
  h5 {
    font-size: 14px;
    line-height: 17px;
    color: #787d83;
    margin-bottom: 22px;
  }
  .header-menu-item-spacer {
    height: 15px !important;
  }
  .enrollment-button-separator {
    height: 5px !important;
  }
}
