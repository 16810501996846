@import "styles/core.scss";

.registration-form {
  @include mq($until: tablet) {
    top: 24px;
    max-width: calc(100vw - 48px);
  }
  .ant-modal-content {
    border-radius: 16px;
  }
  &--content {
    padding: 0 38px;
    @include mq($until: desktop) {
      height: 85vh;
      overflow-y: scroll;
    }
  }
    h3 {
      font-weight: 700;
      color: $primary;
      font-size: 24px;
    }
    p {
      color: #787d83;
      font-size: 16px;
    }
    h4 {
      color: $gray1;
      font-weight: 700;
      font-size: 16px;
    }
    .ant-input {
      height: 43px;
      border-radius: 8px;
      border: 1px solid #cdd6e1;
      font-size: 16px;
      &:disabled {
        background-color: #f1f7ff;
        color: #cdd6e1;
      }
    }
  }


.registration-form-wrapper {
  .ant-modal-close {
    top: 16px;
    right: 16px;
  }
}

.coverage-selection {
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.coverage-selection-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 164px;
  border: 1px solid transparent;
  border-radius: 16px;
  padding: 0 21px;
  text-align: center;
  background-color: #f1f7ff;
  opacity: 0.5;
  width: 100%;
  margin-bottom: 16px;
  &.selected {
    opacity: 1;
    border: 1px solid #787d83;
  }
  h4 {
    color: #787d83;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    margin-bottom: 10px;
  }
  p {
    color: #787d83;
    font-size: 16px;
    line-height: 19.5px;
  }
}

.form-label-with-info {
  display: inline-flex;
  align-items: center;
  .icon {
    margin-left: 5px;
    font-size: 10px;
    position: relative;
    top: 1px;
    opacity: 0.4;
  }
}

.registration-submit-button,
.confirmation-done-button {
  height: 48px;
  background-color: #f72585;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  &:hover,
  &:visited,
  &:active {
    background-color: #f72585;
    color: #fff;
  }
}

.confirmation-done-button {
  width: 93px;
}

.confirmation-modal {
  .modal-content {
    padding: 0 38px;
  }
  h3 {
    color: #4361ee;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
  }
  p {
    font-size: 16px;
    color: #787d83;
    margin: 10px 0;
    font-weight: bold;
  }
  ul {
    list-style: initial;
    li {
      font-size: 16px;
      color: #787d83;
      padding: initial;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}


.registration-information-box {
  background-color: #F72585;
  border-radius: 8px;
  padding: 8px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 20px;
  span {
    text-align: center;
    display: inline-block;
    display: block;
    color: #ffffff;
    max-width: 414px;
    font-size: 14px;
    line-height: 17px;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}



.registration-error-box {
  background-color: #f03c3c;
  border-radius: 8px;
  padding: 8px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 20px;
  span {
    text-align: center;
    display: inline-block;
    display: block;
    color: #ffffff;
    max-width: 414px;
    font-size: 14px;
    line-height: 17px;
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}
