@import "styles/core.scss";

.example-card-wrapper {
  display: inline-flex;
  position: relative;
  top: -35px;
  &.remove-top {
    top: 0;
    position: static;
  }
  @include mq($until: desktop) {
    top: 0 !important;
    position: static;
  }

  img {
    height: 197px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
    border-radius: 24px;
  }
}

.example-card-desc {
  h4 {
    font-weight: 700;
    color: #1d242d;
    text-align: center;
    margin-bottom: 7px;
    font-size: 16px;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    color: #787d83;
    text-align: center;
  }
}
